import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SmallBoxLink from "../components/sm-box-link"

import mainImg from "../images/undraw_taking_notes_tjaf.svg";
import aukroImg from "../images/inzerce/aukro.png"
import marketplaceImg from "../images/inzerce/marketplace.png"
import pcbazarImg from "../images/inzerce/pcbazar.png"
import oldcomputersImg from "../images/inzerce/oldcomputers.jpg"
import ebayImg from "../images/inzerce/ebay.png"
import bazosImg from "../images/inzerce/bazos.png"

function InzercePage() {
    return (
      <Layout>
          <SEO
        title="Inzerce"
      />

        <section className="text-gray-700 body-font">
            <div className="container p-2 mx-auto">
                <div className="flex flex-col text-center w-full mb-4">
                    <h2 className="uppercase text-xs text-blue-600 tracking-widest font-medium title-font mb-1">Rozcestník</h2>
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Inzerce</h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Doporučujeme tyto skupiny a tržiště</p>
                </div>
                <div className="flex flex-col-reverse lg:flex-row items-center">
                    <div className="w-1/2 lg:w-2/6 self-center m-4">
                        <img className="" src={mainImg} />
                    </div>
                    <div className="flex flex-wrap justify-center items-center w-full lg:w-4/6">
                    <SmallBoxLink 
                        alt="Facebook Marketplace"
                        link="https://www.facebook.com/marketplace/"
                        image={marketplaceImg}
                    />
                    <SmallBoxLink 
                        alt="PC bazar"
                        link="https://www.facebook.com/groups/bazarpc"
                        image={pcbazarImg}
                    />
                    <SmallBoxLink 
                        alt="Old Computers"
                        link="https://www.facebook.com/groups/oldkomp"
                        image={oldcomputersImg}
                    />
                    <SmallBoxLink 
                        alt="Aukro"
                        link="https://aukro.cz/"
                        image={aukroImg}
                    />
                    <SmallBoxLink 
                        alt="Ebay"
                        link="https://www.ebay.com/"
                        image={ebayImg}
                    />
                    <SmallBoxLink 
                        alt="Bazoš"
                        link="https://www.bazos.cz/"
                        image={bazosImg}
                    />
                    </div>
                </div>
            </div>

        </section>
      </Layout>
      );
    }
    
export default InzercePage;
