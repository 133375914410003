import React from "react";
import PropTypes from "prop-types";

export default function SmallBoxLink(props) {
  return(   <div className="relative flex justify-center items-center bg-white rounded-lg m-2 sm:m-4 hover:bg-blue-200 transition-colors duration-300 ease-out">
                <img className="w-32 h-16 sm:w-40 sm:h-20 p-1 sm:p-3 rounded-lg object-contain" src={props.image} alt={props.alt}/>
                <a className="absolute top-0 left-0 right-0 bottom-0 rounded-lg" href={props.link}></a>
            </div>
)}

SmallBoxLink.defaultProps = {
    alt: `Header`,
    link: `https://www.pcporadna.net/`,
    image: `https://picsum.photos/1200/500`
};

SmallBoxLink.propTypes = {
    alt: PropTypes.string,
    link: PropTypes.string,
    image: PropTypes.string
};